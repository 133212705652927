import { UAParser } from 'ua-parser-js'

export async function generateUniqueSessionId() {
  const components = [
    navigator.userAgent,
    navigator.language,
    screen.colorDepth,
    screen.width + 'x' + screen.height,
    new Date().getTimezoneOffset(),
    !!window.sessionStorage,
    !!window.localStorage,
    !!window.indexedDB,
    navigator.hardwareConcurrency,
    navigator.platform || 'unknown',
    navigator.vendor || 'unknown',
    (() => {
      try {
        const canvas = document.createElement('canvas')
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
        if (!gl) return 'webgl-not-supported'
        const webgl = gl as WebGLRenderingContext
        return webgl.getParameter(webgl.VENDOR) + '::' +
               webgl.getParameter(webgl.RENDERER) + '::' +
               webgl.getParameter(webgl.VERSION)
      } catch (e) {
        return 'webgl-error'
      }
    })(),
    (() => {
      const fontTestString = 'mmmmmmmmmmlli'
      const testSize = '72px'
      const baseFonts = ['monospace', 'sans-serif', 'serif']
      const fontList = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana']

      const testFont = (font: string) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        if (!context) return ''

        context.font = testSize + ' ' + font + ', ' + baseFonts[0]
        context.fillText(fontTestString, 10, 72)
        return canvas.toDataURL().slice(0, 20)
      }

      return fontList.map(font => testFont(font)).join('|')
    })()
  ]

  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = 200
    canvas.height = 50

    // Text with characteristics that vary based on OS/font rendering
    ctx!.textBaseline = 'top'
    ctx!.font = '14px Arial'
    ctx!.fillStyle = '#f60'
    ctx!.fillRect(0, 0, 100, 25)
    ctx!.fillStyle = '#069'
    ctx!.fillText('Browser Fingerprint', 2, 15)

    const dataURL = canvas.toDataURL()
    components.push(dataURL)
  } catch (e) {
    components.push('canvas-not-supported')
  }

  const componentsStr = components.join('###')

  let hashStr
  try {
    const msgBuffer = new TextEncoder().encode(componentsStr)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    hashStr = hashArray.map(b => b.toString(16).padStart(2, '0')).join('').substring(0, 12)
  } catch (e) {
    let hash = 0
    for (let i = 0; i < componentsStr.length; i++) {
      const char = componentsStr.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash
    }
    hashStr = Math.abs(hash).toString(36).padStart(8, '0')
  }

  const timestamp = Math.floor(Date.now() / (1000 * 60 * 60))

  localStorage.setItem('SBMT_DID', `${hashStr}:${timestamp}`)
  return `${hashStr}:${timestamp}`
}

export function getSignals() {
  const parser = new UAParser();
  const result = parser.getResult()

  const signals: any = {
    suspicious: false,
    suspiciousTerms: [],
    headless: false,
    webdriver: false,
    notifications: false,
    navProps: false
  }

  const userAgent = navigator.userAgent.toLowerCase()
  const suspiciousTerms = ['headless', 'phantomjs', 'selenium', 'webdriver', 'puppet', 'crawler', 'spider', 'bot', 'playwright', 'electron', 'slimer', 'nightmare']
  const isSuspicious = suspiciousTerms.some(term => userAgent.includes(term))

  if (isSuspicious) {
    signals.suspicious = true
    signals.suspiciousTerms = suspiciousTerms.filter(term => userAgent.includes(term))
  }

  const isHeadlessChrome =
    /Chrome/.test(result.browser.name || '') &&
    /HeadlessChrome/.test(navigator.userAgent)

  const isHeadlessFirefox =
    /Firefox/.test(result.browser.name || '') &&
    /Headless/.test(navigator.userAgent)

  if (isHeadlessChrome || isHeadlessFirefox) {
    signals.headless = true
  }

  if (navigator.webdriver) signals.webdriver = true

  if (navigator.permissions) {
    navigator.permissions.query({name: 'notifications'})
      .then(permission => {
        if (permission.state === 'denied') signals.notifications = true
      })
  }

  const navigatorProps = Object.getOwnPropertyNames(Navigator.prototype)
  if (!navigatorProps.includes('plugins') ||
      !navigatorProps.includes('mimeTypes')) {
    signals.navProps = true
  }

  return signals
}
